/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
.blur-sm {
  backdrop-filter: blur(1px);
}
.blur-md {
  backdrop-filter: blur(10px);
}
.contrast-sm {
  filter: contrast(130%);
}
.contrast-md {
  filter: contrast(150%);
}
}
